import React, {useEffect, useState} from "react";
import Reaptcha from 'reaptcha';
import style from "./InquiryWrite.module.scss";
import {autoHypen} from "../../../../Function/autoHypen";
import {checkForm} from "./Feature/checkForm";
import ComponentContainer from "../../../../global/ComponentContainer/ComponentContainer";
import {handleMyPageError, queryLink} from "../../../../Function/sendQuery";
import {Link} from "react-router-dom";

const axios = require('axios').default;

const InquiryWrite = () => {
    const [inquiryFrom, setInquiryFrom] = useState({});
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const token = localStorage.getItem("token") || "";

    useEffect(() => {
        if (token && token.slice(0, 6) === "Bearer") {
            axios.get(queryLink + "/users", {
                headers: {
                    'Access-Control-Allow-Origin': queryLink,
                    Authorization: token
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        const {name, companyName, email, phone, companyPhone} = response.data;

                        setInquiryFrom({
                            isUser: true,
                            name: name,
                            companyName,
                            email,
                            phone,
                            companyPhone
                        })
                    }
                })
                .catch((error) => {
                    handleMyPageError(error);
                });
        }
        // eslint-disable-next-line
    }, []);

    const config = {
        title: "Inquiry",
        sectionID: "Inquiry",
        bgImg: "InquiryMypage"
    };

    const recaptchaKey = "6LdO9NgUAAAAABN7BXzzVDGoDNdqQ1KBaESH2g84";

    const addInquiryForm = (name, value) => {
        setInquiryFrom({
            ...inquiryFrom,
            [name]: value
        });
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        addInquiryForm(name, value);
    };

    const handlePhoneChange = (e) => {
        const {name} = e.target;
        const value = autoHypen(e.target.value).slice(0, 13);
        addInquiryForm(name, value);
    };

    const handleFileUpload = (e) => {
        const {files} = e.target;

        let form = new FormData();
        form.append(e.target.name, files[0]);

        const type = files[0].name.slice(-4);

        if (type !== ".zip" && type !== ".jpg" && type !== ".png") {
            alert("서버에 올라갈 수 있는 파일의 형식은 .zip / .jpg / .png 입니다.");
            return false;
        }

        axios.post(`${queryLink}/upload`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin' : "*"
            }
        })
            .then((res) => {
                addInquiryForm("file", res.data.file);
            })
            .catch((err) => {
                alert("파일을 업로드 하는 과정에서 문제가 발생했습니다.");
                console.log(err);
            })
    };

    const handleVerify = (e) => {
        setRecaptchaToken(e);
    };

    const handleSubmit = (e) => {
        if (!recaptchaToken) {
            alert("구글 reCAPTCHA 인증을 완료해주세요!");
            e.preventDefault();
            return false;
        }

        setInquiryFrom({
            ...inquiryFrom,
            recaptchaToken
        });

        checkForm(inquiryFrom);

        e.preventDefault();
    };

    return (
        <ComponentContainer config={config}>
            <form onSubmit={handleSubmit} className={style.form}>
                <div className={style.inputContainer}>
                    <div className={style.inputRow}>
                        <label htmlFor="name">작성자 <b>*</b></label>

                        <input type="text" id="name" name="name" required={true} onChange={handleChange}
                               value={inquiryFrom.name || ""}/>
                    </div>

                    <div className={style.inputRow}>
                        <label htmlFor="companyName">업체명 <b>*</b></label>

                        <input type="text" id="companyName" name="companyName" required={true}
                               onChange={handleChange} value={inquiryFrom.companyName || ""}/>
                    </div>

                    <div className={style.inputRow}>
                        <label htmlFor="email">이메일 <b>*</b></label>

                        <input type="email" id="email" name="email" required={true} onChange={handleChange}
                               value={inquiryFrom.email || ""}/>
                    </div>
                </div>

                <div className={style.inputContainer}>
                    {!inquiryFrom.isUser ? <div>
                        <div className={style.inputRow}>
                            <label htmlFor="newPassword">비밀번호 <b>*</b></label>

                            <input type="password" id="newPassword" name="newPassword" required={true}
                                   onChange={handleChange}/>
                        </div>

                        <div className={style.inputRow}>
                            <label htmlFor="checkNewPassword">비밀번호 확인 <b>*</b></label>

                            <input type="password" id="checkNewPassword" name="checkNewPassword" required={true}
                                   onChange={handleChange}/>
                        </div>
                    </div> : ""}

                    <div className={style.inputRow}>
                        <label htmlFor="phone">핸드폰번호 <b>*</b></label>

                        <input type="tel" id="phone" name="phone" required={true} onChange={handlePhoneChange}
                               value={inquiryFrom.phone || ""}/>
                    </div>

                    <div className={style.inputRow}>
                        <label htmlFor="companyPhone">회사번호</label>

                        <input type="tel" id="companyPhone" name="companyPhone" onChange={handlePhoneChange}
                               value={inquiryFrom.companyPhone || ""}/>
                    </div>
                </div>

                <div className={style.inputContainer}>
                    <div className={style.titleRow}>
                        <div className={style.selectWrapper}>
                            <select name="articleClass" onChange={handleChange} required={true}>
                                <option defaultValue={true} hidden={true}>분류를 선택해주세요.</option>
                                <option value="상품문의">상품문의</option>
                                <option value="기타문의">기타문의</option>
                            </select>
                        </div>

                        <input type="text" name="title" onChange={handleChange} required={true}/>
                    </div>

                    <textarea name="content" onChange={handleChange} required={true}/>
                </div>

                <div className={style.inputContainer}>
                    <div className={`${style.inputRow} ${style.optionRow}`}>
                        <label htmlFor="">파일 첨부</label>

                        <label className={style.text} htmlFor="file">
                            {inquiryFrom.file || "파일 업로드하기"}
                        </label>
                        <input type="file" name="file" id="file" onChange={handleFileUpload} accept=".png,.jpg,.zip"/>
                    </div>
                </div>

                <div className={style.inputContainer}>
                    <div className={`${style.inputRow} ${style.optionRow}`}>
                        <label htmlFor="recaptcha" className={style.labelCaptcha}>자동등록방지 <b>*</b></label>

                        <Reaptcha
                            sitekey={recaptchaKey}
                            onVerify={handleVerify}
                            className={style.recaptcha}
                        />
                    </div>
                </div>

                <div className={style.btnContainer}>
                    <button type="submit" className={style.submitBtn}>
                        확인
                    </button>

                    <Link to="/contact/inquiry" className={style.submitBtn}>취소</Link>
                </div>
            </form>
        </ComponentContainer>
    );
};

export default InquiryWrite;