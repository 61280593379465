import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {DeviceContext} from "../../App";
import style from "./Header.module.scss";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import headerItem from "./headerItem";
import {queryLink} from "../Function/sendQuery";
import MobileMenuList from "./MobileMenu/MobileMenuList";
import {FaBars} from "react-icons/all";
import {Collapse} from 'react-collapse';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import HeaderSubMenu from "./HeaderSubMenu/HeaderSubMenu";

const axios = require('axios').default;

const Header = (props) => {
    const [userData, setUserData] = useState(undefined);
    const [height, setHeight] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("token") && localStorage.getItem("token").slice(0, 4) === "Bear") {
            axios.get(queryLink + "/users", {
                headers: {
                    'Access-Control-Allow-Origin': queryLink,
                    Authorization: localStorage.getItem("token")
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        const {name} = response.data;

                        setUserData({
                            name: name
                        })
                    }
                })
                .catch((error) => {
                    if (error === 401) {
                        localStorage.removeItem("token");
                    }
                });
        }

        window.addEventListener("scroll", handleBg);

        // eslint-disable-next-line
    }, []);

    // 언어 변경
    const {isKorean} = props.props || {isKorean: true};

    // eslint-disable-next-line
    const onChangeLang = () => {
        props.onChange({isKorean: !isKorean});
    };

    const handleBg = () => {
        const offsetY = window.pageYOffset + 250;
        if (window.location.pathname === "/") {
            if (offsetY > window.innerHeight / 2.75) {
                setHeight(true);
            } else {
                setHeight(false);
            }
        } else {
            switch (device) {
                case "p" :
                    offsetY > 375 ? setHeight(true) : setHeight(false);
                    break;
                case "t" :
                    offsetY > 565 ? setHeight(true) : setHeight(false);
                    break;
                case "m" :
                    offsetY > 255 ? setHeight(true) : setHeight(false);
                    break;
                default :
                    setHeight(false);
            }
        }
    };

    // 너비 감지하여 맞춤형 내용 표시
    const device = useContext(DeviceContext) || 't';
    const logo = device === 'p' ? "white" : "short";
    const items = headerItem;

    // 접고 펴기
    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!isOpen);
    };

    const [isHover, setHover] = useState(-1);

    const handleChange = () => {
        window.scrollTo(0, 0);
        handleOpen();
    };

    const handleHover = (e) => {
        setHover(e);
    };

    const handleHoverOut = () => {
        setHover(-1);
    };

    return (
        <header className={style.header} onMouseLeave={handleHoverOut}>
            <nav className={`${style.mobile} ${height ? style.true : ""}`}>
                <Link to="/" className={style.link}>
                    <LazyLoadImage
                        className={style.logo}
                        src={"/images/logo_" + logo + ".svg"}
                        alt={"성연인더스트리 로고"}
                    />
                </Link>

                <div className={style.headerMenu}>
                    {device === "p" &&
                    <ul className={style.headItem}>
                        {items[0].map((item, i) => <HeaderMenu key={i} data={item} index={i} onChange={e => handleHover(e)}/>)}
                    </ul>
                    }
                </div>

                <div className={style.headerMenu}>
                    {device === "p" && !userData ?
                        <ul className={style.headItem}>
                            {items[1].map((item, i) => <HeaderMenu key={i} data={item} hoverDisable={true}/>)}
                        </ul>
                        : ""}

                    {device !== "p" &&
                    <div className={style.headItem}>
                        <button onClick={handleOpen}>
                            <FaBars/>
                        </button>
                    </div>
                    }

                    {userData && device === "p" ?
                        <div className={style.headItem}>
                            <Link to="/mypage">{userData.name} 님</Link>
                            <Link to="/logout">로그아웃</Link>
                        </div> : ""}
                </div>
            </nav>

            {device === "p" && <nav onMouseOver={() => handleHover(isHover)} className={style.headerSubMenu}>
                <Collapse isOpened={isHover !== -1}>
                    <HeaderSubMenu targetNum={isHover}/>
                </Collapse>
            </nav>
            }

            {device !== "p" && <nav className={style.mobileMenu}>
                <Collapse isOpened={isOpen}>
                    <MobileMenuList userdata={userData || ""} onChange={handleChange}/>
                </Collapse>
            </nav>
            }
        </header>
    );
};

export default Header;