import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Reaptcha from 'reaptcha';
import style from "./InquiryModify.module.scss";
import {checkForm} from "./Feature/checkForm";
import ComponentContainer from "../../../../global/ComponentContainer/ComponentContainer";
import {headerConfig, queryLink, unknownError} from "../../../../Function/sendQuery";
import {createBrowserHistory} from "history";

const history = createBrowserHistory({
    forceRefresh: true
});

const axios = require('axios').default;

const InquiryModify = () => {
    const {id} = useParams();

    const [inquiryFrom, setInquiryFrom] = useState({});
    const [recaptchaToken, setRecaptchaToken] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            alert("권한이 없습니다.");
            history.goBack();
            return false;
        }

        axios({
            method: "GET",
            url: queryLink + `/inquiry/${id}`,
            headers: {
                ...headerConfig,
                Authorization: localStorage.getItem("token")
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    const {
                        title,
                        articleClass,
                        content,
                        file,
                        inquiryId
                    } = response.data;
                    setInquiryFrom({
                        id,
                        title,
                        articleClass,
                        content,
                        file,
                        inquiryId
                    });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("권한이 없습니다.");
                            history.goBack();
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
        // eslint-disable-next-line
    }, []);

    const config = {
        title: "Inquiry",
        sectionID: "Inquiry",
        bgImg: "InquiryMypage"
    };

    const recaptchaKey = "6LdO9NgUAAAAABN7BXzzVDGoDNdqQ1KBaESH2g84";

    const addInquiryForm = (name, value) => {
        setInquiryFrom({
            ...inquiryFrom,
            [name]: value
        });
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        addInquiryForm(name, value);
    };

    const handleFileUpload = (e) => {
        const {files} = e.target;

        let form = new FormData();
        form.append(e.target.name, files[0]);

        const type = files[0].name.slice(-4);

        if (type !== ".zip" && type !== ".jpg" && type !== ".png") {
            alert("서버에 올라갈 수 있는 파일의 형식은 .zip / .jpg / .png 입니다.");
            return false;
        }

        axios.post(`${queryLink}/api/upload`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin' : "*"
            }
        })
            .then((res) => {
                addInquiryForm("file", res.data.file);
            })
            .catch((err) => {
                alert("파일을 업로드 하는 과정에서 문제가 발생했습니다.");
                console.log(err);
            })
    };

    const handleVerify = (e) => {
        setRecaptchaToken(e);
    };

    const handleSubmit = (e) => {
        if (!recaptchaToken) {
            alert("구글 reCAPTCHA 인증을 완료해주세요!");
            e.preventDefault();
            return false;
        }

        setInquiryFrom({
            ...inquiryFrom,
            recaptchaToken
        });

        checkForm(inquiryFrom);

        e.preventDefault();
    };

    return (
        <ComponentContainer config={config}>
            <form onSubmit={handleSubmit} className={style.form}>
                <div className={style.inputContainer}>
                    <div className={style.titleRow}>
                        <div className={style.selectWrapper}>
                            <select name="articleClass" onChange={handleChange} required={true} defaultValue={inquiryFrom.articleClass}>
                                <option value="상품문의">상품문의</option>
                                <option value="기타문의">기타문의</option>
                            </select>
                        </div>

                        <input type="text" name="title" onChange={handleChange} required={true} defaultValue={inquiryFrom.title}/>
                    </div>

                    <textarea name="content" onChange={handleChange} required={true} defaultValue={inquiryFrom.content}/>
                </div>

                <div className={style.inputContainer}>
                    <div className={`${style.inputRow} ${style.optionRow}`}>
                        <label htmlFor="file">파일 첨부</label>

                        <input type="file" name="file" id="file" onChange={handleFileUpload} accept=".png, .jpg, .zip"/>
                    </div>
                </div>

                <div className={style.inputContainer}>
                    <div className={`${style.inputRow} ${style.optionRow}`}>
                        <label htmlFor="recaptcha" className={style.labelCaptcha}>자동등록방지 <b>*</b></label>

                        <Reaptcha
                            sitekey={recaptchaKey}
                            onVerify={handleVerify}
                            className={style.recaptcha}
                        />
                    </div>
                </div>

                <div className={style.btnContainer}>
                    <button type="submit" className={style.submitBtn}>
                        확인
                    </button>
                </div>
            </form>
        </ComponentContainer>
    );
};

export default InquiryModify;