import React, {useEffect, useState} from "react";
import style from "./InquiryList.module.scss";
import {Link} from "react-router-dom";
import {queryLink, headerConfig} from "../../../../Function/sendQuery";
import ComponentContainer from "../../../../global/ComponentContainer/ComponentContainer";
import InquiryListItem from "./Item/InquiryListItem";
import Loading from "../../../../global/loading/loading";
import {FiChevronRight} from "react-icons/all"

const axios = require('axios').default;


const InquiryList = () => {
    const [list, setList] = useState({});
    const [isLoad, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true);
        axios({
            method: "post",
            url: queryLink + "/inquiry/api/list",
            headers: {
                ...headerConfig,
            },
            data : {
                size: 15
            }
        })
            .then((result) => {
                setList(result.data.data);
                setLoad(false);
            });

        // eslint-disable-next-line
    }, []);

    const config = {
        title: "Inquiry",
        sectionID: "Inquiry",
        bgImg: "InquiryMypage"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>

                <span className={style.current}> <FiChevronRight /> <b>전체</b> | 총 {list.length || 0}건</span>

                {!list ? <div className={style.noListWarn}>
                    <h1 className={style.warnText}>등록된 게시글이 없습니다.</h1>
                </div> : ""}

                <div className={style.listWrap}>
                    <div className={style.header}>
                        <div className={style.column}>id</div>
                        <div className={style.column}>분류</div>
                        <div className={style.column}>제목</div>
                        <div className={style.column}>날짜</div>
                    </div>

                    {isLoad ? <Loading /> : ""}
                    {!isLoad && list.length > 0 ? list.map((item, i) => <InquiryListItem key={i} data={item} />) : ""}
                </div>

                {/*todo : 데이터가 있을때 데이터 설정하기*/}

                <div className={style.buttonWrap}>
                    <Link to="/contact/inquiry/write" className={style.button}>문의하기</Link>
                </div>
            </div>
        </ComponentContainer>
    );
};

export default InquiryList;